.moreArticle {
  .card {
    .card-body {
      background-color: #fcfbfe;
      .bloggerName {
        margin-left: 6px;
        color: rgba(20, 17, 37, 1);
        font-weight: 500;
        margin-bottom: 10px;
      }
    }
  }
}
