@media screen and (max-width: 780px) {
  .calculator {
    .container {
      .bdgt-selector {
        .SrImg {
          margin-bottom: 1rem !important;
        }
      }
    }
  }
}

.calculator {
  .container {
    .alert {
      text-align: center;
      margin-top: 5rem;
      background-color: #d0342c;
      color: white;
      font-size: 24px;
      font-weight: 500;
    }
    .main-text {
      margin-top: 6rem;
      font-weight: 900;
      font-size: 48px;
      color: #0a071b;
    }
    .text {
      margin-top: 2rem;
      margin-bottom: 4rem;
    }
    .disable {
      opacity: 0.3;
      pointer-events: none;
    }
    .bdgt-selector {
      input {
        margin-bottom: 1rem !important;
      }
      ::placeholder {
        margin-left: 8px;
      }

      .label-right {
        float: right;
      }
      .bdgt-split {
        margin-right: 3rem;
        color: rgba(81, 56, 237, 1);
        font-size: 14px;
        font-weight: 500;
        margin-bottom: 5px !important;
      }
      input {
        height: 47px;
        background: #ffffff;
        border: 1px solid #dedde4;
        border-radius: 7px;
        width: 100%;
      }
      input[type="number"]::placeholder {
        padding-left: 10px;
      }
      .reload-icon {
        margin-bottom: 0.5rem;
        margin-left: 12px;
      }

      .SrImg {
        margin-bottom: 11rem;
        margin-right: 3rem;
      }
    }
    .disable-icon {
      .icon {
        position: absolute;
        z-index: 2;
        height: 12rem;
        margin: 14rem;
      }
    }
    .card {
      span {
        font-size: 22px;
        font-weight: 700;
      }
      p {
        margin-bottom: 7px !important;
        color: #5b5675;
        font-weight: 500;
        font-size: 14px;
      }

      background: #fef7eb;
      .more-result {
        background: #ffffff;
        border-radius: 5px;

        color: black;
      }
      .modal-body {
        .card {
          box-shadow: 0px 4px 40px rgba(0, 0, 0, 0.1);
          border: none;
          background-color: #ffffff;
          .head {
            background-color: #f8f7fc;
            padding: 6px 14px;
            h5 {
              color: #0a071b;
              line-height: 24px;
              font-weight: 500;
              font-size: 20px;
            }
          }
        }
      }
      .modalFooter {
        margin-bottom: 3rem;
        h6 {
          font-size: 18px;
          font-weight: 600 !important;
          margin-top: 25px;
          color: #0a071b;
        }
        .spinner-border {
          width: 1rem;
          height: 1rem;
          border: 3px solid white;
          border-right-color: transparent;
          margin-left: 16px;
        }
        .subscribe {
          background: linear-gradient(0deg, rgba(0, 0, 0, 0.65), rgba(0, 0, 0, 0.65)), #5138ee;
          box-shadow: 0px 94px 114px -56px rgb(43 29 103 / 8%);
          border-radius: 8px;
          color: white;
          font-size: 16px;
          font-weight: 600;
          width: 80%;
          height: 50px;
          margin-top: 1rem;
          margin-bottom: 1rem;
        }
      }
    }
    .footer {
      background-color: rgba(238, 193, 117, 1);
      color: white;
      margin: 0 -16px -16px;
      height: 100px;
    }
  }
}

.label-right {
  float: right;
}

.estimator-card {
  .card {
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.65), rgba(0, 0, 0, 0.65)), #5138ee;
    box-shadow: 0px 94px 114px -56px rgba(43, 29, 103, 0.08);
    border-radius: 16px;
    margin: 1rem 0 8rem 0;
    width: 90%;
    p {
      font-weight: 700;
      font-size: 30px;
      color: #ffffff;
    }
    .leadsImg {
      margin: -4px 3rem;
    }
    .card-body {
      margin-top: 1.5rem;
      margin-bottom: 1.5rem;
    }
  }
}

.error-icon {
  font-size: 26px;
  color: red;
  margin-right: 12px;
}
.success-icon {
  font-size: 26px;
  color: green;
  margin-right: 12px;
}
