@media screen and (min-width: 1100px) {
  .dashboard {
    .header {
      padding-right: 8rem;
    }
  }
}

@media screen and (max-width: 1200px) {
 
    .calculations{
    .card {
      width: 100%!important;
      height: 200px!important;
      overflow: scroll!important;
    }
    .card-body{
      width: 100%!important;
    }
    }
  
}

.dashboard {
  margin-left: 1.5rem;
  .heading {
    margin-left: 3rem;
    font-size: 26px;
    font-weight: 700;
    margin-bottom: 12px;
    
  }
  .card {
    width: 90%;
    height: 15rem;
    border-radius: 9px;
    .card-body {
      margin-left: 1.5rem;
      .body {
        h5 {
          font-size: 26px;
          font-weight: 700;
          margin-top: 1.5rem;
        }
        .industry {
          font-size: 16px;
          font-weight: 500;
          color: #616161;
        }
      }
    }
    .footer {
      background-color: #e1dfec;
      border-bottom-left-radius: 8px 7px;
      border-bottom-right-radius: 8px 7px;
      .deafult {
        font-size: 18px;
        font-weight: 600;
        margin: 0 1.5rem;
        color: #616161;
        .secondary {
          margin-left: 18px;
        }
      }
      a {
        text-decoration: none;
      }
    }
  }
}

.calculations {
  .heading {
    .arrowBtn {
      color: black;
      font-size: 20px;
      font-weight: 500;
      margin-right: 22px;
      margin-top: 3px;
    }
    
  }
  .strategy {
    color: #b3b3b3;
  }
  .card {
    box-shadow: 5px 3px 16px rgba(20, 17, 37, 0.03);
    border: 0;
    border-radius: 12px;
    .table {
      overflow-x: auto;
      table {
        width: 100%;
       
      }
    }
  }
  .heading {
    .head {
      p {
        font-size: 16px;
        color: #808080;
        font-weight: 500;
      }
    }
    hr {
      color: #e1dfec;
    }
    th{
      padding-bottom:18px ;
    }
    .table-body{
      border: 2px solid ghostwhite;
    padding: 12px;
    text-align: start;
    }
    td{
      padding: 10px;
      border: 2px solid ghostwhite;
    }
  }
  .name {
    font-weight: 500;
    font-size: 16px;
    color: black;
  }
}
