.rc-slider {
  .rc-slider-rail {
    height: 8px;
  }
  .rc-slider-track {
    background-color: #5138ee;
    height: 8px;
  }
  .rc-slider-handle {
    left: 2%;
    font-size: 12px;
    width: 35px;
    height: 20px;
    margin-top: -8px;
    background-color: #8776f2;
    opacity: 1;
    color: white;
    border: 0;
    border-radius: 25px 25px;
  }

  .rc-slider-handle:hover {
    border-color: transparent;
  }
}

.select {
  .css-1s2u09g-control {
    border-color: #e1dfce;
    border-radius: 8px;
    min-height: 29px;
    max-height: 43px;
    .css-6j8wv5-Input {
      height: 40px !important;
      margin: -4px;
    }
  }
}
.css-b62m3t-container {
  width: 100%;
}
.form-label {
  color: rgb(91, 86, 117);
  font-weight: 500;
  font-size: 16px;
}
