@media screen and ( max-width: 992px) and (min-width:768px) {
  .contactCard {
    .card {
      .inner-ContactCard {
        .SndMsgBtn {
          width: 11rem;
        }
      }
    }
  }
}
@media screen and (min-width: 992px) {
  .contactCard {
    .card {
      .inner-ContactCard {
        .SndMsgBtn {
          width: 15rem;
        }
      }
    }
  }
}
@media screen and (max-width: 765px) {
  .contactCard {
    .card {
      .inner-ContactCard {
        .SndMsgBtn {
          width: 15rem;
        }
      }
    }
  }
}
@media screen and (max-width: 550px) {
  .contactCard {
    .card {
      .contactInnerCard {
        margin-left: 4px !important;
      }
    }
  }
}
@media screen and (max-width: 550px) {
  .contactCard {
    .card {
      .contactInnerCard {
        margin-left: 4px !important;
      }
    }
  }
}

.contactCard {
  margin-bottom: 9rem;
  .card {
    box-shadow: 301px 11px 202px 124px rgb(81 56 238) inset;

    background: rgb(81, 56, 238) !important;

    height: 27rem !important;
    margin-top: 2rem !important;
    h3 {
      color: white;
    }
    p {
      font-weight: 500;
      color: white;
    }
    .brand-icons {
      color: white !important;
    }
    .brandNestIcons {
      margin-right: 1.5rem;
    }
    .contactInnerCard {
      margin-left: 3rem;
      margin-top: 4rem;
    }
    .contact-icons {
      margin-right: 9px;
      color: white;
    }
    .inner-ContactCard {
      .card {
        background-color: #ffffff !important;
        border-radius: 16px;
        box-shadow: 0px 94px 114px -56px rgb(43 29 103 / 8%);

        p {
          color: rgba(91, 86, 117, 1);
          margin-bottom: 0 !important;
          margin-top: 16px;
        }
        input {
          margin-top: 7px;
          height: 47px;
          background: #ffffff;
          border: 1px solid #dedde4;
          border-radius: 7px;
          width: 100%;
        }
        ::placeholder{
          padding-left: 8px;
        }
        
        .SndMsgBtn {
          color: white;
          background-color: rgba(81, 56, 238, 1);
          border: 0;
          border-radius: 8px;
          height: 42px;
        }
      }
    }
  }
}
