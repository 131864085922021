header {
  width: 100%;
  .headerLink {
    color: rgba(10, 7, 27, 1);
    text-decoration: none;
  }
  .name {
    font-weight: 600;
    font-size: 21px;
    color: rgba(10, 7, 27, 1);
    margin-right: 31px;
  }

  .header-text {
    font-weight: 700;
    font-size: 18px;
    color: rgba(10, 7, 27, 1);
    text-decoration: none;
    margin-left: 8px;
  }

  Navbar {
    width: 100%;
  }

  .navbar-light .navbar-nav .nav-link {
    
    color: rgba(10, 7, 27, 1);
    font-weight: 500;
    font-size: 16px;
  }
  .navbar-expand-lg .navbar-nav .nav-link {
    padding-right: 2rem;
  }
  .login {
    .nav-link {
      padding-right: 0 !important;
    }
    .changePassword {
      font-weight: 500;
      color: white !important;

      background: #5138ee;
      border-radius: 8px;
    }
    .signupBtn {
      font-weight: 500;
      color: white !important;
      width:90px;
      background: #5138ee;
      border-radius: 8px;
    }

    @media only screen and (max-width: 991px) {
      .login {
        display: none; /* إخفاء عناصر div المحتوية على زر الدخول */
      }
    }

    .divider {
      border: 1px solid #bcb8d0;
      transform: rotate(90deg);
      margin: 1.25rem 0rem 0 1rem;
      width: 16px;
      height: 0px;
    }
  }
}
