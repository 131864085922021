@media only screen and (max-width: 767px) {
  footer {
    .brand-icons {
      text-align: start !important;
    }
  }
}

$font-size-sm: 16px;
$font-size-md: 18px;
$font-size-lg: 20px;
$font-size-xl: 20px;
footer {
  .container {
    h5 {
      font-weight: 600;
      
      // ms screen
      @media (max-width: 576px) {
        font-size: $font-size-sm;
      }

      // md screen
      @media (min-width: 577px) and (max-width: 768px) {
        font-size: $font-size-md;
      }

      // lg screen
      @media (min-width: 769px) and (max-width: 992px) {
        font-size: $font-size-lg;
      }

      // xl screen
      @media (min-width: 993px) and (max-width: 1200px) {
        font-size: $font-size-xl;
      }
    }
    p {
      font-weight: 500;
      font-size: 14px;
      color: rgba(140, 135, 166, 1);
    }
    input {
      margin-top: 7px;
      height: 47px;
      background: #ffffff;
      border: 1px solid #dedde4;
      border-radius: 7px;
      max-width: 70%;
      width: 100%;
    }
    .signupBtn {
      color: white;
      background-color: rgba(81, 56, 238, 1);
      border: 0;
      width: 6rem;
      border-radius: 8px;
      height: 42px;
    }
  }
  .footer-text {
    font-weight: 500;

    color: rgba(53, 53, 53, 1);
  }
  .divider {
    border: 1px solid #bcb8d0;
    transform: rotate(90deg);
    margin: 10px 8px 0 8px;
    width: 16px;
    height: 0px;
  }
  .footer-divider {
    border: 1px solid #5138ee;
    color: rgb(81, 56, 238);
    width: 20%;
    margin: 8px auto ;
    border-radius: 4px;
  }
  .brand-icons {
    color: rgba(81, 56, 238, 1);
    text-align: end;
    margin-bottom: 2rem;
  }
  .brandNestIcons {
    margin-right: 1.5rem;
  }

  .privacy-btn{
    cursor:pointer;
  }
}
