$font-primary: "Poppins", Arial, sans-serif;
$primary: #f8b739;

body {
  font-family: $font-primary;
  font-size: 14px;
  line-height: 1.8;
  font-weight: normal;
  background: #fafafa;
  color: red;
}
a {
  transition: 0.3s all ease;
  color: $primary;
  &:hover,
  &:focus {
    text-decoration: none !important;
    outline: none !important;
    box-shadow: none;
  }
}
button {
  transition: 0.3s all ease;
  &:hover,
  &:focus {
    text-decoration: none !important;
    outline: none !important;
    box-shadow: none !important;
  }
}
h1,
h2,
h3,
h4,
h5,
.h1,
.h2,
.h3,
.h4,
.h5 {
  line-height: 1.5;
  font-weight: 400;
  font-family: $font-primary;
}

.ftco-section {
  padding: 7em 0;
}

.ftco-no-pt {
  padding-top: 0;
}
.ftco-no-pb {
  padding-bottom: 0;
}
//HEADING SECTION
.heading-section {
  font-size: 28px;
  // color: $black;
  small {
    font-size: 18px;
  }
}

//COVER BG
.img {
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
}

.navbar {
  padding: 15px 10px;
  background: #fff;
  border: none;
  border-radius: 0;
  margin-bottom: 40px;
  box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.1);
  margin-right: 5px;
}

.navbar-btn {
  box-shadow: none;
  outline: none !important;
  border: none;
}

.line {
  width: 100%;
  height: 1px;
  border-bottom: 1px dashed #ddd;
  margin: 40px 0;
}

//SIDEBAR
.wrapper {
  width: 100%;
}

#sidebar {
  border-right: 1px solid #e1dfec;
  min-width: 300px;
  max-width: 300px;
  background: white;
  color: #fff;
  transition: all 0.3s;
  &.active {
    margin-left: -300px;
  }
  .logo {
    display: block;
    width: 120px;
    height: 120px;
    margin: 0 auto;
    span {
      display: block;
    }
  }
  ul.components {
    color: #bcb8d0;
    font-weight: 400;
    font-size: 17px;

    li:hover {
      font-weight: 400;
      background-color: #f5f3fe;
      color: #5138ee;
      transition: all 0.1s ease-in-out;
      border-radius: 5px;
    }

    .icon {
      color: #bcb8d0;
      margin-top: 8px;
      margin-right: 17px;
      font-size: 20px;
    }
  }
  a {
    text-decoration: none;
    color: #bcb8d0;
  }
}

a[data-toggle="collapse"] {
  position: relative;
}

.dropdown-toggle::after {
  display: block;
  position: absolute;
  top: 50%;
  right: 0;
  transform: translateY(-50%);
}

#content {
  width: 100%;
  padding: 0;
  min-height: 100vh;
  transition: all 0.3s;
}
button {
  background-color: white;
}
