.about {
  color: blue;
  margin-left: 10%;
  margin-right: 12%;
}
.about-paragraph {
  margin-left: 10%;
  margin-right: 12%;
  font-size: 23px;
  .about-div{
    margin-left: 1.5rem;
    margin-bottom: 1rem;
  }
}
.homepage {
  color: blue;
  margin-top: 5%;
  margin-left: 10%;
  margin-right: 12%;
}
.text {
  margin-top: 2%;
  margin-left: 12%;
  margin-right: 12%;
  font-size: 23px;
}
.homepage-paragraph {
  margin: 0px 0px 0px 0px;
}

.margin-paragraph {
  margin-left: 40px;
}
.p-margin {
  margin-left: 25px;
}
.layout-margin {
  margin-bottom: 18%;
}

.estimate-btn {
  font-weight: 400;
  color: white;
  background-color: rgba(81, 56, 238, 1);
  border: 0;
  width: 20rem;
  border-radius: 6px;
  height: 42px;
  text-decoration: none;
}
