@import "./variables";
@media screen and (max-width: 767px) {
  section {
    .container {
      .card {
        .card-body {
          margin-top: 0 !important;
          margin-left: 0 !important;
        }
      }
    }
  }
}

$font-size-sm: 21px;
$font-size-md: 25px;
$font-size-lg: 28px;
$font-size-xl: 28px;

section {
  .container {
    .card {
      border-radius: 16px;
      background: #ffffff;
      border: 0;

      h3 {
        font-weight: 900;
        // ms screen
        @media (max-width: 576px) {
          font-size: 16px;
        }

        // md screen
        @media (min-width: 577px) and (max-width: 768px) {
          font-size: 18px;
        }

        // lg screen
        @media (min-width: 769px) and (max-width: 992px) {
          font-size: 20px;
        }

        // xl screen
        @media (min-width: 993px) and (max-width: 1900px) {
          font-size: 20px;
        }
        p {
          font-weight: 500;
          font-size: 18px;
        }
      }
      .audienceImg {
        width: 25rem;
        margin: 0.75rem 0 2rem 0;
      }
      .card-body {
        .icon {
          width: 4.5rem;
        }
      }
    }
    .main-text {
      font-family: "arial";
      font-weight: 800;
      color: rgba(10, 7, 27, 1);
      // font-size: $font-size-xl;

      // ms screen
      @media (max-width: 576px) {
        font-size: $font-size-sm;
      }

      // md screen
      @media (min-width: 577px) and (max-width: 768px) {
        font-size: $font-size-md;
      }

      // lg screen
      @media (min-width: 769px) and (max-width: 992px) {
        font-size: $font-size-lg;
      }

      // xl screen
      @media (min-width: 993px) and (max-width: 1900px) {
        font-size: $font-size-xl;
      }
    }
  }
  .card-body {
    .icon {
      width: 8.5rem;
      // margin-left: -25px;
    }
    h3 {
      font-weight: 800;

      // ms screen
      @media (max-width: 576px) {
        font-size: 16px;
      }

      // md screen
      @media (min-width: 577px) and (max-width: 768px) {
        font-size: 18px;
      }

      // lg screen
      @media (min-width: 769px) and (max-width: 992px) {
        font-size:20px;
      }

      // xl screen
      @media (min-width: 993px) and (max-width: 1900px) {
        font-size: 20px;
      }
    }
    p {
      font-weight: 500;
      font-size: 18px;
      color: #5b5675;
    }
  }
}
.budget-card {
  .container {
    width: 90%;
    margin-top: 1rem;
    margin-bottom: 7rem;
    .error {
      color: red;
      font-size: 15px;
      font-weight: 600;
      margin-bottom: 1rem;
    }
  }

  .card {
    background: #ffffff;
    box-shadow: 0px 60px 150px 10px rgba(43, 29, 103, 0.08);
    border-radius: 16px;
    .card-body {
      padding: 2rem 2rem !important;
    }
    select {
      height: 53px;
      border: 1px solid #dedde4;
      width: 100%;
      align-items: center;
      background-color: hsl(0, 0%, 100%);
      border-color: hsl(0, 0%, 80%);
      border-radius: 4px;
      min-height: 48px;
    }
    .input-group {
      width: 100%;
    }
    h4 {
      color: rgba(91, 86, 117, 1);
      font-weight: 500;
      font-size: 24px;
    }
    p {
      margin-left: auto;
      color: rgba(81, 56, 238, 1);
      font-weight: 500;
      font-size: 18px;
    }
    .estimate-btn {
      font-weight: 400;
      color: white;
      background-color: rgba(81, 56, 238, 1);
      border: 0;
      width: 11rem;
      border-radius: 6px;
      height: 3rem;
      text-decoration: none;
    }
  }
}

.chatCard {
  .card {
    background-color: rgba(81, 56, 238, 1) !important;
    height: 18rem !important;
    .card-body {
      margin-top: 3rem;
      h3 {
        color: #ffffff;
      }
      p {
        color: #ffffff;
      }
      .chatBtn {
        background-color: white;
        color: rgba(10, 7, 27, 1);
        border: 0;
        width: 12rem;
        border-radius: 8px;
        height: 42px;
      }
    }
  }
}
.paymentCard {
  .container {
    margin-top: 4rem;
    margin-bottom: 7rem;
    .header {
      background: #f8f7fc;

      .header-text {
        padding: 0 2rem;
        margin-top: 1rem;
        font-weight: 500;
        font-size: 20px;
        color: #0a071b;
        p {
          margin-left: auto;
        }
      }
    }
    .card {
      background: #ffffff;
      box-shadow: 0px 4px 40px rgba(0, 0, 0, 0.1);
      border-radius: 15px;
      .card-body {
        .body-text {
          padding: 0 1rem;
          color: #5b5675;
          font-weight: 500;
          font-size: 16px;
          p {
            margin-left: auto !important;
          }
        }
        h5 {
          margin-left: 8rem;
          margin-top: 4px;
        }
        label {
          color: #5b5675;
          font-weight: 500;
          font-size: 14px;
          margin-left: 20px;
        }
        input {
          margin-top: 7px;
          width: 100%;
          height: 47px;
          background: #ffffff;
          border: 1px solid #dedde4;
          border-radius: 7px;
        }
      }
      form {
        input {
          border: 0px;
          width: 10%;
          height: 2em;
        }
        input[name="payment"] {
          accent-color: rgba(81, 56, 238, 1);
          margin-left: 2rem;
        }

        label {
          font-weight: 500;
          font-size: 18px;
          color: #455065;
          margin-left: 20px;
        }
      }
      .subscribe {
        background: linear-gradient(
            0deg,
            rgba(0, 0, 0, 0.65),
            rgba(0, 0, 0, 0.65)
          ),
          #5138ee;
        box-shadow: 0px 94px 114px -56px rgba(43, 29, 103, 0.08);
        border-radius: 8px;
        color: white;
        font-size: 18px;
        font-weight: 600;
        width: 45%;
        height: 62px;
        margin-top: 3rem;
        margin-bottom: 2rem;
      }
    }
  }
}

.main-slider {
  .rc-slider {
    .rc-slider-rail {
      height: 18px;
    }
    .rc-slider-track {
      background-color: #5138ee;
      height: 18px;
    }
    .rc-slider-handle {
      top: 85%;
      left: 1.5%;
    }

    .rc-slider-handle:hover {
      border-color: transparent;
    }
  }
}
