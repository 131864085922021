form {
  .container {
    .logoImg {
      margin-top: 4rem;
    }
    .main-card {
      display: flex;
      justify-content: center;
    }
    .signUp{
      color: rgb(81, 56, 238);
      text-decoration: none;
      font-weight: 600;
      margin-left: 4px;
    }
    .signUp-form {
      margin-top: 2rem;
      width: 31rem;
      .error{
        color: red;
        font-size: 18px;
        font-weight: 500;
      }
      .card {
        padding: 2rem 2rem;
        background: #ffffff;
        box-shadow: 0px 8px 60px rgba(100, 116, 139, 0.15);
        border-radius: 16px;

        h3 {
          color: #101010;
          font-weight: 700;
          font-size: 24px;
          margin-bottom: 1.25rem;
        }
        p {
          margin-bottom: 8px !important;
          color: rgba(0, 0, 0, 1);
          font-weight: 500;
          margin-top: 1rem !important;
        }
        input {
          height: 52px;
          background: #ffffff;
          border: 1px solid #dedde4;
          border-radius: 8px;
          width: 100%;
        }
        ::placeholder {
          padding-left: 8px;
        }
        .loginBtn {
          box-shadow: 0px 94px 114px -56px rgba(43, 29, 103, 0.08);
          background: linear-gradient(
              0deg,
              rgba(0, 0, 0, 0.4),
              rgba(0, 0, 0, 0.4)
            ),
            #5138ee;
          color: white;
          width: 100%;
          height: 40px;
          border-radius: 8px;
        }
        .returnBtn {
          background-color: white;
          color: rgba(81, 56, 238, 1);
          font-weight: 500;
        }
        .textforget {
          color: rgba(148, 163, 184, 1);
          font-weight: 500;
          font-size: 14px;
          text-decoration: none;
          margin-left: auto;
          margin-top: 2px;
        }
        .custom-terms{
          color: #94A3B8;
        }
        .terms {
          text-decoration: none;
          margin-left: 3px;
          font-weight: 500;
          color: rgba(81, 56, 238, 1);
        }
      }
    }
  }
}
