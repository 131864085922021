@media only screen and (max-width: 1000px) {
  header {
    .navbar-nav {
      .nav-item {
        padding: 0 !important;
      }
    }
  }
}

@media only screen and (max-width: 1000px) {
  footer {
    section {
      flex-direction: column;
    }
  }
  .footerBrandCont {
    width: 100% !important;
    /* display: flex; */
  }
  .footerFormBox {
    width: 100% !important;
  }
  .footerInput {
    margin-bottom: 10px;
  }
}

@media only screen and (max-width: 1000px) {
  .section1TextBox {
    width: unset;
  }
  .section1ImgWraper {
    width: 100%;
  }
  .section1TextBox p {
    width: 100%;
  }
  .calculate-container {
    position: unset;
    width: unset;
    height: unset;
  }
  .calculate-container form {
    padding: 1rem 10px !important;
  }
  .section1 {
    padding: 0 10px;
  }
  .section2 {
    padding: 10px;
  }
  .styleBoxMainContn {
    flex-direction: column;
    align-items: unset;
  }
  .styleBoxMainContn div:nth-child(1) {
    width: 100% !important;
  }
  .section2-styledBox-wrapper {
    padding: 0;
    width: unset !important;
    margin: 0 0 5px 0;
  }
  .section2-styledBox-wrapper2 {
    padding: 0;
    width: unset !important;
    margin: 0 0 5px 0;
  }
  .section2-styledBox {
    margin: 0 !important;
    margin-bottom: 12px;
  }
  .section2-styledBox-wrapper3 {
    padding: 0;
    width: unset !important;
    margin: 0 0 45px 0;
  }
  .section3 {
    padding: 10px;
  }
  .section3-h {
    padding: inherit;
  }
  .section3ContentCont {
    flex-direction: column;
  }
  .card-title {
    margin: 1rem 0;
  }
  .card {
    margin: 0 0 1rem 0;
    width: unset !important;
  }
  .btn-sndFb {
    width: 100%;
  }

  .feedBCont {
    flex-direction: column;
  }
  .fbFormPara {
    width: 100% !important;
  }
  textarea {
    width: 100%;
  }
  .fbFormInputs {
    width: 100% !important;
  }
  .section5CardCont {
    flex-direction: column;
  }
}
